import React from 'react';

interface ButtonProps {
	children: React.ReactNode;
	onClick?: () => void;
	type?: 'button' | 'submit' | 'reset';
}

const Button: React.FC<ButtonProps> = ({
	children,
	onClick,
	type = 'button',
}) => {
	return (
		<button
			type={type}
			onClick={onClick}
			className="w-full bg-indigo-500 text-white font-semibold py-2 rounded-md hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500"
		>
			{children}
		</button>
	);
};

export default Button;

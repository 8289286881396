import React, {
	FormEvent,
	FormHTMLAttributes,
	forwardRef,
	useImperativeHandle,
	useState,
} from 'react';
import Button from 'shared/components/Button/Button';
import InputField from 'shared/components/InputField';
import { UserCredentials } from '../authTypes';

export type UserFormProps = {
	onSubmit?: (event: FormEvent<HTMLFormElement>) => void;
} & FormHTMLAttributes<HTMLFormElement>;

export type UserFormHandle = {
	getCredentials: () => UserCredentials;
};

export const UserForm = forwardRef<UserFormHandle, UserFormProps>(
	({ onSubmit, ...props }, ref) => {
		const [email, setEmail] = useState('');
		const [password, setPassword] = useState('');

		useImperativeHandle(ref, () => {
			return {
				getCredentials() {
					return { email, password };
				},
			};
		});

		return (
			<div className="flex items-center justify-center bg-gray-100">
				<div className="bg-white shadow-md rounded-lg p-8 max-w-md w-full">
					<h2 className="text-2xl font-bold text-gray-800 text-center mb-6">
						Sign In
					</h2>
					<form onSubmit={onSubmit} {...props}>
						<InputField
							value={email}
							onChange={(e) => {
								setEmail(e.target.value);
							}}
							label="Email"
							type="email"
							id="email"
							placeholder="Enter your email"
							required
						/>
						<InputField
							value={password}
							onChange={(e) => {
								setPassword(e.target.value);
							}}
							label="Password"
							type="password"
							id="password"
							placeholder="Enter your password"
							required
						/>
						<Button type="submit">Sign in</Button>
					</form>
					<p className="text-gray-600 text-sm text-center mt-4 cursor-pointer">
						Don't have an account?{' '}
						<span className="text-indigo-500 hover:underline">Sign up</span>
					</p>
				</div>
			</div>
		);
	}
);

import React, { InputHTMLAttributes } from 'react';

type InputFieldProps = {
	label: string;
	type: string;
	id: string;
	placeholder: string;
	required?: boolean;
	value: string;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & InputHTMLAttributes<HTMLInputElement>;

const InputField: React.FC<InputFieldProps> = ({
	label,
	type,
	id,
	placeholder,
	required = false,
	value,
	onChange,
	...props
}) => {
	return (
		<div className="mb-4">
			<label htmlFor={id} className="block text-gray-700 font-semibold mb-2">
				{label}
			</label>
			<input
				{...props}
				value={value}
				onChange={onChange}
				type={type}
				id={id}
				autoComplete=""
				name={id}
				className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-indigo-500 focus:outline-none"
				placeholder={placeholder}
				required={required}
			/>
		</div>
	);
};

export default InputField;
